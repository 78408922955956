<template>
    <div>
        <top>
            <!-- //插槽 -->
            <div class="cfff  tc mt140">
                <div class="fs42">安迩——可信赖的综合服务平台</div>
                <div class="fs20 mt20 " style="opacity: 0.8;">一站式服务体系</div>
                <div  class="rowjcic mt70 fs20 fwb">
                    <div @click="goWebPage(1)" class="w200 h50 lh50 br25 bcfff">
                        <a style="color: #1DB9DF; text-decoration: none;" target="_blank" href="http://www.cnunit.cn">进入ERP管理系统</a>
                    </div>
                    <div @click="goWebPage(2)" class="w200 h50 lh50 br25 bcfff  ml30">
                        <a style="color: #1DB9DF; text-decoration: none;"  target="_blank" href="http://www.myaner.vip/安迩健康.apk">下载安迩健康APP</a>
                    </div>
                </div>
            </div>
        </top>
        <!-- 企业快速 -->
        <div class="pt50  ">
            <div class="fs38 c000 tc fwb">企业快速入驻通道</div>
            <div class="fs16 tc mt15 family">一键入驻轻松解决</div>
            <div class="mt50 rowjcic ">
                <div class="w340 h360 br4 bcfff pt40 pb36 crowjbic mr30  animation" @click="$router.push('/business')">
                    <img class="w100 h100" src="@/assets/img/img/shangjia.png" alt="">
                    <div class="fs24 c000 fwb">商家入驻</div>
                    <div class="w160 c999 tc lh18 fs14">一键入驻快速进行让您快速进行医院入驻</div>
                    <div class="enter">点击进入</div>
                </div>
                <div class="w340 h360 br4 bcfff pt40 pb36 crowjbic mr30  animation" @click="$router.push('/hospital')">
                    <img class="w88 h88" src="@/assets/img/img/yiyuan.png" alt="">
                    <div class="fs24 c000 fwb">医院入驻</div>
                    <div class="w160 c999 tc lh18 fs14">一键入驻快速进行让您 快速进行医院入驻</div>
                    <div class="enter">点击进入</div>
                </div>
                <div class="w340 h360 br4 bcfff pt40 pb36 crowjbic mr30 animation" @click="$router.push('/parmacy')">
                    <img class="w88 h88" src="@/assets/img/img/yaodian.png" alt="">
                    <div class="fs24 c000 lh18 fwb">药店入驻</div>
                    <div class="w160 c999 tc fs14">一键入驻快速进行让您 快速进行药店入驻</div>
                    <div class="enter">点击进入</div>
                </div>
            </div>
        </div>
        <!-- 新闻 -->
        <div class="newBackImg cfff">
            <div class="tc fs38 pt53 "> 新闻资讯</div>
            <div class="tc fs14 mt15" style="opacity: 0.88;">关于安迩日常新闻</div>
            <div class="ml370 rowic mt60">
                <div class="bcfff w540 h380" style="padding: 43px 42px 0 36px;">
                    <div class="c333 fs24"> 安迩健康</div>
                    <div class="c999 mt18 fs16">您的一站式服务提供商</div>
                    <div class="fs14 mt32 lh26 c333"> 济南安迩健康管理咨询有限公司依托依托实体医院的互联网医院服务；互联
                        网信息服务；药品互联网信息服务；医疗器械互联网信息服务；互联网直播
                        技术服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动，
                        具体经营项目以相关部门批准文件或许可证件为准）
                    </div>
                    <div class="healthy">
                        <div @click="$router.push('/about')" class="enter mt50 " style="color: #333;">点击进入</div>
                    </div>
                </div>
                <div class="outer-container" v-if="newList&&newList.length>0">
                    <div class=" outer-container w540 h340 cfff fs16 "
                        style=" background-color: #00CC89; opacity: 0.9; overflow-y: scroll;">
                        <div v-for="(item,index) in newList" @click="goNewInfoPage(item.id)" :key="index"
                            class="pr30  rowjbic pl30 h60 borderB-f1">
                            <div class="w350 yc1">{{item.title}}</div>
                            <div>{{formatDate(item.createTime)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pt50">
            <div class="fs38 c000 tc fwb">人才招聘</div>
            <div class="fs16 tc mt15 family">一键入驻轻松解决</div>
        </div>
        <div class="mt55 mb100">
            <div class="rowjcic">
                <div @click="$router.push('/recruit')" class="recruitmentBox ml30 br5 pr33 pl33 pt50 pb60 crowjbic" v-for="(item,index) in recruitList.slice(0,5)" :key="index">
                    <img class="h90 w90" src="@/assets/img/img/gangweiimg.png" alt="">
                    <div class="fs22  moveTitle fwb yc2">{{item.title}}</div>
                    <div class="lh26 moveContent yc3">{{item.synopsis}}</div>
                </div>
            </div>
        </div>
        <bottom></bottom>
    </div>
</template>
<script>
    import top from '@/components/top'
    import bottom from '@/components/common/bottom'
    export default {
        data() {
            return {
                newList: [],//新闻列表
                recruitList:[],//面试列表
            }

        },

        components: {
            bottom,
            top
        },
        mounted() {
           console.log(this.$options)
            this.getNewsList()//获取列表新闻
            this.getRecruitList()//获取面试列表
            
        },
        methods: {



           








            formatDate(date) { //设置时间转换格式
                var time = new Date(date); //将日期写在这。
                var y = time.getFullYear();  //获取年
                var m = time.getMonth() + 1;  //获取月
                m = m < 10 ? '0' + m : m;  //判断月是否大于10
                var d = time.getDate();  //获取日
                d = d < 10 ? ('0' + d) : d;  //判断日期是否大10
                return y + '-' + m + '-' + d;  //返回时间格式
            },

            getNewsList() {//获取列表新闻
                this.$axios({
                    url: 'getMessageList',
                    data: {
                        type: 1
                    }

                }).then(res => {

                    if (res.data.code == 0) {
                        this.newList = res.data.data

                    } else {
                        this.newList = []
                    }
                })
            },
            getRecruitList() {//获取招聘列表
                this.$axios({
                    url: 'getMessageList',
                    data: {
                        type: 2
                    }

                }).then(res => {

                    if (res.data.code == 0) {
                        this.recruitList = res.data.data

                    } else {
                        this.recruitList = []
                    }
                })
            },

            goNewInfoPage(id) {
                this.$router.push({
                    path: '/newsInfo',
                    query: {
                        id
                    }
                })
            }
        },
    }

</script>
<style>
    #app {
        background: #F2F5F7;
    }

    .outer-container ::-webkit-scrollbar {

        display: none;
        /* Chrome Safari */


    }

    .enter {
        width: 100px;
        height: 36px;
        border-radius: 20px;
        opacity: 1;
        border: 1px solid #C8C8C8;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
    }


    .newBackImg {
        margin-top: 70px;
        background-image: url(../../assets/img/img/bannertwo.png);
        width: 100%;
        height: 660px;
        background-size: 100% 100%;
    }

    .content {

        overflow-x: hidden;
        /*x轴禁止滚动*/
        overflow-y: scroll;
        /*y轴滚动*/
    }

    .content::-webkit-scrollbar {
        display: none;
        /*隐藏滚动条*/
    }

    .content {

        -ms-overflow-style: none;
        /* IE 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    .recruitmentBox {
        width: 290px;
        height: 350px;
        background: #FFFFFF;
        border: 1px solid #E6E6E6;
    }

    .recruitmentBox:hover {
        background: linear-gradient(180deg, #3CE1AE 0%, #2ED087 100%);
    }

    .moveContent {
        color: #979797;
    }

    .recruitmentBox:hover .moveContent {
        color: #FFFFFF;
    }

    .moveTitle {
        color: #000;
    }

    .recruitmentBox:hover .moveTitle {
        color: #FFFFFF;
    }

    .borderB-f1:hover {
        font-weight: 600;
    }

    .animation:hover {
        cursor: pointer;
        -webkit-animation-name: vanishIn;
        animation-name: vanishIn;
        animation-duration: 0.5s;
        animation-iteration-count: 1,

    }

    .animation:hover .enter {
        background-color: rgba(56, 221, 164, 1);
        color: #FFFFFF;
        border: none;
    }

    .healthy .enter:hover {
        background-color: rgba(56, 221, 164, 1);
        color: #fff !important;
        border: none;
        cursor: pointer;
    }
</style>